const InitialState = {
  signIn: false,
  token: "",
  role: "",
  username: "",
  // userId: "",
  // companyId: "", //only cs will be use
  // compCode: "",
  // reqToken:10,
  issuper:false,
};

const login = (state = InitialState, { type, payload }) => {
  let newState = {};
  switch (type) {
    case "sign_in":
      newState = {
        ...state,
        signIn: true,
        token: payload.access_token,
        role: payload.role,
        username: payload.user,
        // role: payload.type,
        // userId: payload.userId,
        // username: payload.username,
        // companyId: payload.companyId,
        // compCode: payload.compCode,
        // qrCode: payload.qrCode,
        // refCode: payload.refCode,
        // reqToken:payload.reqToken,
        // issuper:JSON.parse(payload.issuper?? "false"),
      };
      break;

    case "sign_out":
      newState = {
        ...state,
        signIn: false,
        token: "",
        role: "",
        username: "",
        // userId: "",
        // companyId: "",
        // qrCode: "",
        // refCode: "",
        // reqToken:10,
        // issuper:false,
      };
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
      break;

    default:
      newState = state;
  }
  return newState;
};

export default login;
