import {settings} from "../config/appConfig";

//const currentYear = `2020 - ${new Date().getFullYear()}`;
const currentYear = "2021";

const copyright =  {
    year : currentYear,
    appName: settings.appName,
    fullText : function() {
        return  `${this.appName} ©${currentYear} Created by ${settings.companyName}`;
    }
}

const wthModeText = (text) => {

    if (text === null || text === "") {
        return "";
    }else {
        if (text.toLowerCase() === "comm2bank") {
            return "To Bank";
        }else if (text.toLowerCase() === "comm2wallet") {
            return "To Wallet";
        }else {
            return "";
        }
    }
}

let convertToBool = (value)=>{
    if (value === null || value==='' || value === undefined) {
      return false;
    }else {
       if (value.toString().toUpperCase() ==='Y') {
          return true;
       }else {
         return false;
       }
    }
}

let boolToString = (value)=>{
    if (value === null || value===''|| value === undefined) {
      return 'N';
    }else {
       if (JSON.parse(value)===true) {
          return 'Y';
       }else {
         return 'N';
       }
    }
  }

export {copyright, wthModeText , convertToBool, boolToString};