import { isMobile } from "react-device-detect";

const style = {
    pageHeaderContainer : {
        justifyContent: isMobile ? "" : "space-between",
        display: "flex",
        flexDirection: isMobile ? "column" : "",
        alignItems: isMobile ? "center" : "",
      },
    refreshBtn: {
      marginLeft: 10,
    },
    phoneNumStyle:{
      width:"76.58%"
    },
    phoneNumStyle2:{
      width:"23.75em"
    },
    downlineFontStyle: {
      fontSize:"13px",
      fontWeight:"bolder",
    },
    attnFontStyle:{
      color:"red",
      fontWeight:"bolder",
    },
    smallFont:{
      fontSize: "9pt"
    },
    nowrap:{
      whiteSpace:"no-wrap",
    },
    addBankTabStyle:{
      backgroundColor:"#f5f3f3"
    },
    addBankFormStyle:{
      height:"300px",
      marginLeft: "2.0%",
      marginTop: "1.1%",
    },
    editBankFormStyle:{
      marginLeft: "2.0%",
      marginTop: "1.1%",
    },
    memberBtn: {
      width:"150px", 
      marginBottom:"8px"
    },
    lockMemberBtn:{
      //width: "70px",
      //marginRight: 20,
      backgroundColor: "#F7B105",
      border: "none",
      color: "#FFFFFF",
    },
    unlockMemberBtn:{
      backgroundColor: "#0000FF",
      border: "none",
      color: "#FFFFFF",
    },
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    tableDivide50: {
      height:"60%",
    },
    tableDivide40: {
      height:"30%",
    },
    paginationStyle: {
      marginTop: 50,
    },
    formItem: {
      width:"80%"
    },
    formItem2: {
      width:"83.54%"
    },
    width80:{
      width:"80%"
    },
    fullWidth:{
      width:"100%"
    },    
    datePickerStyle: {
      width: 280,
    },
    trxnSearchDatePicker:{
      marginBottom:"1%",
    },
    dateToMarginTop:{
        marginTop:"5px",
    },
    show:{
      display:"block",
    },
    hide : {
      display:"none",
    },
    visHide: {
      visibility: "hidden",
    },
    bankAmountStyle:{
      marginLeft:"auto",
      marginRight:0,
    },
    numberStyle:{
      textAlign:"right",
    },
    filterDataStyle:{
        marginLeft:"10px", 
        fontWeight:"bolder"
    },
    filterLabelStyle:{
      fontWeight:"normal", 
      marginLeft:"2px"
    },
    tabelContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
    },
    tagStyle:{
      width:"92px",
    },
    yesStyle:{
      color:"green",
      fontWeight:"bolder"
    },
    noStyle:{
      color:"red",
      fontWeight:"bolder"
    },
    actionBtnStyle:{
      width:"100%",
      color:"white",
    },
    buttonStyle:{
      fontSize:"14px"
    },
    actionBtnApprStyle:{
      backgroundColor:"#009E6D",
    },
    actionBtnRejStyle:{
      backgroundColor:"#AA0000",
    },
    actionBtnKIVStyle:{
      backgroundColor:"#C57700",
    },
    memberSearchStyle:{
      width:180,
    },
    memberTrxnStyle:{
      marginLeft:"280px",
    },
    actionBtnListStyle:{
      listStyleType: "none",
    },
    referralDetailsStyle:{
      border:"1px solid black", 
      textAlign:"left" , 
      width:"270px", 
      minHeight:"130px",
      maxHeight: "180px",
    },
    downlineDetailsStyle:{
      border:"1px solid black", 
      textAlign:"left" , 
      width:"270px", 
      minHeight:"130px",
      maxHeight: "180px",
    },
    downlineDetailsSearchStyle:{
      border:"1px solid black", 
      textAlign:"left" , 
      width:"270px", 
      minHeight:"130px",
      maxHeight: "180px",
      marginLeft:"-13%",
    },
    referralStatsStyle:{
      //border:"1px solid black", 
      textAlign:"left" ,
      width:"270px",         
      minHeight:"130px",
      maxHeight: "180px",
      backgroundColor:"#E8EAEA"

    },
    actionBtnListItemStyle:{
      display:"inline",
      width: "3%",
    },
    menuFontSize: {
      fontSize:
        window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
    },
    displayEvenly:{
      display: "flex",
      justifyContent:"space-evenly",
    },
    attn: {
      fontSize:"20px",
    },
    notAuthorizedContainer:{
      textAlign:"center",
      color:"red",
      fontSize:"150%"
    },
    noHighlight : {
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none",
      marginTop: "15px"
    },
    cardContainer: {
      display: "flex",
      width: "100vw",
      overflowX: "scroll",
      overflowY: "hidden",
      flexDirection: "row",
      paddingTop: 10,
      paddingBottom: 10,
      height:"5%"
    },
    cardStyles: {
      borderRadius: 20,
      fontSize: 15,
      //fontSize: 30,
      boxShadow: "5px 8px 5px 5px rgba(208, 216, 243, 0.6)",
      marginLeft: 20,
      height: "30%",
      //height: 150,
      marginRight: 20,
    },
    cardHeadStyles: {
      backgroundColor: "#1DA57A",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      color: "#FFFFFF",
      fontSize: 25,
      width: 350,
    },
    cardTxt: {
      paddingLeft: 20,
      height: 60,
      alignItems: "center",
      display: "flex",
    },
    horizontalLineStyle:{
      height:"3px", 
      backgroundColor:"lightgray", 
      width:"95%"
    },
    reloadCntStyle:{
      marginTop:"0.3rem", 
      width:"4rem", 
      textAlign:"right"
    },
    reloadCntBtnStyle:{
      marginLeft:"1rem", 
      width:"2rem"
    },
    searchBoxStyle: {
      padding: 8 , 
      marginRight : "-6.1rem", 
      background:"white"
    },
    searchBoxStyleMinusMarginRight: {
      padding: 8 , 
      marginRight :  "-1.1rem", 
      background:"white"
    },
    rotate180Deg  : {
      transform:"rotate(180deg)"
    },
    marginLeft10px:{
      marginLeft:"10px",
    },
    marginLeft20px:{
      marginLeft:"20px",
    },
    marginLeft30px:{
      marginLeft:"30px",
    },
    marginLeft250px:{
      marginLeft:"100px",
    },
    width250px:{
      width:"310px",
    },
    underline:{
      textDecorationLine: "underline",
    },
    duplicateTokenMarginTop:{
      marginTop:"280px",
    },
  };

export default style;