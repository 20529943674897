import React from "react";
import { settings } from "../../config/appConfig";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";

export const Logo = (props) =>{
    if (props.logoType==='login') {
        return (<span className="logoTitle">{settings.appName}</span>);
    } else if (props.logoType==='agent' || props.logoType==="root"){
        return (
            <div className="logoTitle" 
            style={{
                fontSize: props.collapsed? 0 : isMobile? 15 :25,
                marginLeft: props.collapsed? 0 : isMobile? 15 :25,
            }}
            >{settings.appName}</div>
        );
    } else {
        return (
            <>
                <div className="logoTitle" 
                    style={{
                        fontSize: props.collapsed? 0 : isMobile? 15 :18,
                    }}
                >{settings.appName}</div>
                {/* <img src={logo.icon} alt="Logo" style={{ maxWidth: "80%" }} />
                {!this.state.collapsed ? (
                  <img src={logo.logo} alt="Logo" style={{ maxWidth: "40%" }} />
                ) : null} */}
            </>
        );

    }
}


Logo.propTypes =  {
    logoType: PropTypes.string,
    collapsed: PropTypes.bool
}

Logo.defaultPorts =  {
    logoType : "",
    collapsed: false
}
