import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Select, Space, Table } from "antd";
import { getUser, addNewUser, editUser, deleteUser } from "../../config/networkConfig";
import { useDispatch } from "react-redux";
import { Option } from "antd/lib/mentions";

export default function User() {
    const [newform] = Form.useForm();
    const [editform] = Form.useForm();
    const [users, setUsers] = useState();
    const [selectUser, setSelectUser] = useState('');
    const [delUser, setDelUser] = useState('');
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [delModalOpen, setDelModalOpen] = useState(false);
    const dispatch = useDispatch();
    const showNewModal = () => {
        setNewModalOpen(true);
    };
    const showEditModal = () => {
        setEditModalOpen(true);
    };
    const showDelModal = () => {
        setDelModalOpen(true);
    };
    const handleNewCancel = () => {
        setNewModalOpen(false);
    };
    const handleEditCancel = () => {
        setEditModalOpen(false);
    };
    const handleDelCancel = () => {
        setDelModalOpen(false);
    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      };
      
      const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
          },
          {
            title: 'Role',
            dataIndex: 'roles',
            key: 'roles',
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size = 'middle'>
                    <Button type="primary" onClick={() => getRecord(record)}>Edit</Button>
                    <Button type="primary" danger onClick={() => {setDelUser(record.name);showDelModal()}}>Delete</Button>
                </Space>
              ),
          }
    ];

    const getRecord = (record) => {
        console.log(record);
        setSelectUser(record);
        showEditModal();
    }

    useEffect(() => {
        getAllUser();
    }, [])

    useEffect(() => {
        editform.setFieldsValue(selectUser);
    }, [selectUser]);

    const onNewFinish = (values) => {
        console.log(values);
        //call api
        addNewUser(values)
        .then((response) => {
            console.log(response);
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to add new user'
            })
        });
    }

    const onEditFinish = (values) => {
        const userArr = [];
        userArr.push(values, selectUser.name);
        console.log(userArr);
        //call api
        editUser(userArr)
        .then((response) => {
            console.log(response);
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to edit user'
            })
        });
    }

    const deleteSelectUser = () => {
        console.log(delUser);
        deleteUser(delUser)
        .then((response) => {
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to delete user'
            })
        })
    }

    function getAllUser() {
        getUser()
        .then((response) => {
            console.log(response);
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            const users = response.map((user, index) => ({
                key: index+1,
                name: user.username,
                target: user.target,
                roles: user.roles
            }))
            console.log(users);
            setUsers(users);
            return;
        })
        .catch((e) => {
            console.log(e);
        });
    }

    return (
        <div>
             <Button 
             type="primary" onClick={showNewModal}
             style={{ overflow: "auto", marginTop: 20, marginLeft:20 }}
             >
                New User
            </Button>
             <Modal title="Add new user" open={newModalOpen} footer={null}>
                <Form
                    {...layout}
                    form={newform}
                    name="addNewUser"
                    // labelCol={{ span: 6 }}
                    // wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    // initialValues={{ remember: true }}
                    onFinish={onNewFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input new user name' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input new user password' }]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="roles"
                        label="Role"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Select
                            placeholder="Select a user role"
                        >
                        <Option value="User">User</Option>
                        <Option value="Superuser">Superuser</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Target"
                        name="target"
                        rules={[{ required: true, message: 'Please input new user target' }]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item  {...tailLayout}>
                        <Button onClick={() => {handleNewCancel();}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ margin: '0 8px' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
             </Modal>


             <Modal title="Edit user" open={editModalOpen} footer={null}>
                <Form
                    {...layout}
                    form={editform}
                    name="editUser"
                    style={{ maxWidth: 600 }}
                    onFinish={onEditFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input new user password' }]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="roles"
                        label="Role"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Select
                            placeholder="Select a user role"
                        >
                        <Select.Option value="User">User</Select.Option>
                        <Select.Option value="Superuser">Superuser</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Target"
                        name="target"
                        rules={[{ required: true, message: 'Please input new user target' }]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button onClick={() => { handleEditCancel();}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ margin: '0 8px' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
             </Modal>
            <Modal
                title="Delete User"
                open={delModalOpen}
                onCancel={handleDelCancel}
                onOk={deleteSelectUser}
                style={{ textAlign: 'center'}}
            >
                <p>Delete {delUser}?</p>
            </Modal>
            <Table
                style={{ overflow: "auto", margin: 20 }}
                columns={columns}
                dataSource={users}
            >
            </Table>
        </div>
    );
}