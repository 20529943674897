import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import { route } from "../../config/route";

export function externalFileNotFile() {
  return (
    <div
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, Page Not Found."
        extra={
          <Link to={route.Default}>
            <Button type="primary">Back to home</Button>
          </Link>
        }
      />
    </div>
  );
}

export function InternalFileNotFile() {
  return (
    <div
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, Page Not Found."
        extra={
          <Link to={route.Default}>
            <Button type="primary">Back to home</Button>
          </Link>
        }
      />
    </div>
  );
}

export const internalConnectionProblem = () => {
  return (
    <div
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Back Home</Button>}
      />
    </div>
  );
};

export default { InternalFileNotFile, externalFileNotFile };
