import React from "react";
import {
  FileSearchOutlined,
  NotificationOutlined,
  ReadOutlined,
  UserOutlined,
  AimOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";

import {
  InternalFileNotFile,
} from "../screens/error/error";
import Logout from "../screens/logout/logout";
import User from "../screens/company/user";
import Notification from "../screens/company/notification";
import Target from "../screens/company/target";
import Schedule from "../screens/company/schedule";

const iconSize = {
  fontSize: window.innerWidth === 280 || window.innerWidth === 320 ? null : 20,
};

const rotate180Deg = {
  transform:"rotate(180deg)"
}

export const logout = [
  {
    key: 0,
    title: "Logout",
    menutitle:"",
    path: "/logout",
    exact: true,
    component: () => <Logout />,
  },
];

export const root = [
  {
    key: 0,
    title: "Notification",
    menutitle:"",
    path: "/",
    exact: true,
    icon: <NotificationOutlined style={iconSize} />,
    component: () => <Notification />,
  },
  {
    key: 1,
    menutitle:"",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const customerService = [
  {
    key: 0,
    title: "User",
    menutitle:"User",
    path: "/user",
    exact: true,
    icon: <UserOutlined style={iconSize} />,
    component: () => <User />,
  },
  {
    key: 1,
    title: "Target",
    menutitle:"Target",
    path: "/target",
    exact: true,
    icon: <AimOutlined style={iconSize} />,
    component: () => <Target />,
  },
  {
    key: 2,
    title: "Notification",
    menutitle:"Notification",
    path: "/",
    exact: true,
    icon: <NotificationOutlined style={iconSize} />,
    component: () => <Notification />,
  },
  // {
  //   key: 3,
  //   title: "Schedule",
  //   menutitle:"Schedule",
  //   path: "/schedule",
  //   exact: true,
  //   icon: <ScheduleOutlined style={iconSize} />,
  //   component: () => <Schedule />,
  // },
  {
    key: 404,
    menutitle:"",
    path: "*",
    exact: true,
    component: () => <InternalFileNotFile />,
  },
];

export const route = {
  /**
   * others
   */
  Default: "/",
  All: "*",
  Dashboard: "/dashboard",
  Login: "/login",
  Logout: "/logout"

};
