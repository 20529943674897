import React, { Component } from "react";
import "../App.less";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { route } from "../config/route";

//Navigate
import Login from "../screens/login/login";
import CustomerService from "../screens/customerService/navigation";
// import Company from "../screens/company/navigation";
import { externalFileNotFile } from "../screens/error/error";

class App extends Component {
  // static propTypes = {
  //   location: PropTypes.object.isRequired,
  // };

  state = {
    collapsed: isMobile ? true : false,
    hidden: true,
  };

  componentDidMount() {
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault(); //disable iphone zooming
    });
  }

  toggle = () => {
    if (isMobile) {
      document.getElementById("menuBackground").style.display = "flex";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  menuBgOnclick = () => {
    if (isMobile) {
      this.setState({
        collapsed: !this.state.collapsed,
      });
      document.getElementById("menuBackground").style.display = "none";
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  render() {
    return this.props.signIn === false ? (
      <Switch>
        <Route path={[route.Default, route.Login]} exact component={Login} />
        <Route path={route.All} component={externalFileNotFile} />
      </Switch>
    ) : (
      <div>
        {(() => {
          if (this.props.role == "User" || this.props.role == "Superuser") {
            return <CustomerService />;
          } 
          // else if (this.props.role === "Company") {
          //   return <Company />;
          // } 
          else {
            return <Login />;
          }
        })()}
      </div>
    );
  }
}

const mapStateToProps = ({ login }) => ({
  role: login.role,
  username : login.username,
});

const mapDispatchToProps = (dispatch) => {
  return { signOut: () => dispatch({ type: "sign_out" }) };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
