import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, notification, Space, Table } from "antd";
import { getTarget, addNewTarget, editTarget, deleteTarget } from "../../config/networkConfig";
import { useDispatch } from "react-redux";

export default function Target() {
    const [newform] = Form.useForm();
    const [editform] = Form.useForm();
    const [targets, setTargets] = useState();
    const [selectTarget, setSelectTarget] = useState('');
    const [delTarget, setDelTarget] = useState('');
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [delModalOpen, setDelModalOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchData, setSearchData] = useState();
    const dispatch = useDispatch();

    const showNewModal = () => {
        setNewModalOpen(true);
    };
    const showEditModal = () => {
        setEditModalOpen(true);
    };
    const showDelModal = () => {
        setDelModalOpen(true);
    };
    const handleNewCancel = () => {
        setNewModalOpen(false);
    };
    const handleEditCancel = () => {
        setEditModalOpen(false);
    };
    const handleDelCancel = () => {
        setDelModalOpen(false);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
      
      const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
          },
          {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
          },
          {
            title: 'FirebaseAuth',
            dataIndex: 'firebaseAuth',
            key: 'firebaseAuth',
          },
          {
            title: 'Number of Schedule',
            dataIndex: 'numOfSchedule',
            key: 'numOfSchedule',
            align: 'center',
            render: (text) => <div style={{ textAlign: 'center' }}>{text}</div>,
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size = 'middle'>
                    <Button type="primary" onClick={() => getRecord(record)}>Edit</Button>
                    <Button type="primary" danger onClick={() => {setDelTarget(record.id);showDelModal()}}>Delete</Button>
                </Space>
              ),
          }
    ];

    const getRecord = (record) => {
        console.log(record);
        setSelectTarget(record);
        showEditModal();
    }

    useEffect(() => {
        getAllTarget();
    }, [])

    useEffect(() => {
        editform.setFieldsValue(selectTarget);
    }, [selectTarget]);

    const onNewFinish = (values) => {
        console.log(values);
        const data = {
            ...values,
            numOfSchedule: parseInt(values.numOfSchedule),
        };
        //call api
        addNewTarget(data)
        .then((response) => {
            console.log(response);
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to add new target'
            })
        });
    }

    const onEditFinish = (values) => {
        const targetArr = [];
        const data = {
            ...values,
            numOfSchedule: parseInt(values.numOfSchedule),
        };
        targetArr.push(data, selectTarget.id);
        console.log(targetArr);
        //call api
        editTarget(targetArr)
        .then((response) => {
            console.log(response);
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to edit target'
            })
        });
    }

    const deleteSelectTarget = () => {
        console.log(delTarget);
        deleteTarget(delTarget)
        .then((response) => {
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to delete target'
            })
        })
    }

    function getAllTarget() {
        getTarget()
        .then((response) => {
            console.log(response);
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            const targets = response.data.map((target, index) => ({
                key: index+1,
                id: target.id,
                topic: target.topic,
                project: target.project,
                firebaseAuth: target.firebaseAuth,
                numOfSchedule: target.numOfSchedule,
            }))
            console.log(targets);
            setTargets(targets);
            setSearchData(targets);
            return;
        })
        .catch((e) => {
            console.log(e);
        });
    }

    const handleSearch = (input) => {
        const searchVal = input.target.value;
        setSearchText(searchVal);
        const filteredData = targets.filter(item => item.topic.includes(searchVal));
        setSearchData(filteredData);
    };    

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', margin: `20px 20px 20px 20px`}}>
                <Input.Search 
                placeholder="Search brand" 
                style={{maxWidth: 200}} 
                allowClear
                value={searchText}
                onChange={(value) => handleSearch(value)}
                
                />
                <Button 
                type="primary" onClick={showNewModal}
                >
                    New Target
                </Button>
            </div>
             <Modal title="Add new target" open={newModalOpen} footer={null}>
                <Form
                    {...layout}
                    form={newform}
                    name="addNewTarget"
                    style={{ maxWidth: 600 }}
                    onFinish={onNewFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    label="Topic"
                    name="topic"
                    rules={[{ required: true, message: 'Please input new target topic' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label="Project"
                    name="project"
                    rules={[{ required: true, message: 'Please input new target project' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="FirebaseAuth"
                        name="firebaseAuth"
                        rules={[{ required: true, message: 'Please input new target firebase auth' }]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Huawei ClientId"
                        name="huawei_clientid"
                        // rules={[{ required: true, message: 'Please input new target huawei_clientid' }]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Huawei Client Secret"
                        name="huawei_clientSecret"
                        // rules={[{ required: true, message: 'Please input new target huawei_clientSecret' }]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Number of Schedule"
                        name="numOfSchedule"
                        rules={[{ required: true, message: 'Please input new target number of schedule' }]}
                        >
                        <Input type="number"/>
                    </Form.Item>

                    <Form.Item  {...tailLayout}>
                        <Button onClick={() => {handleNewCancel();}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ margin: '0 8px' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
             </Modal>


             <Modal title="Edit target" open={editModalOpen} footer={null}>
                <Form
                    {...layout}
                    form={editform}
                    name="editTarget"
                    style={{ maxWidth: 600 }}
                    onFinish={onEditFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                    label="Topic"
                    name="topic"
                    rules={[{ required: true, message: 'Please input new target topic' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label="Project"
                    name="project"
                    rules={[{ required: true, message: 'Please input new target project' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="FirebaseAuth"
                        name="firebaseAuth"
                        rules={[{ required: true, message: 'Please input new target firebase auth' }]}
                        >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Number of Schedule"
                        name="numOfSchedule"
                        rules={[{ required: true, message: 'Please input new target number of schedule' }]}
                        >
                        <Input type="number"/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button onClick={() => { handleEditCancel();}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ margin: '0 8px' }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
             </Modal>
            <Modal
                title="Delete User"
                open={delModalOpen}
                onCancel={handleDelCancel}
                onOk={deleteSelectTarget}
                style={{ textAlign: 'center'}}
            >
                <p>Delete {delTarget}?</p>
            </Modal>
            <Table
                style={{ overflow: "auto", margin: 20 }}
                columns={columns}
                dataSource={searchData}
            >
            </Table>
        </div>
    );
}