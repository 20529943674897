export const settings = {
    "version":"1.0.0",
    "appName": "H5 Console",
    "companyName" : "OgCloud Tech",
    "decimalScale": 4,
    "decimalScaleMobile":2 ,
    "processTypeManual" :"M",
    "processTypeApp"    :"A",
    "pageSizeOptions"   : ["5", "10", "20","50","100"],
    "pageSizeOptionsSmall"   : ["5", "10", "20","50"],
    "pageSizeOptionsMedium"   : ["100", "250", "500"],
    "pageSizeOptionsLarge"   : ["100", "200", "500","1000"],
    "monitorRefreshTime" :"300000", //5 minutes in ms

};