import { Button, Checkbox, DatePicker, Form, Input, Modal, notification, Select, Space, Table, TimePicker, Upload } from "antd";
import { UploadOutlined, DeleteFilled } from '@ant-design/icons';
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { addNewMessage, editMessage, getMessage, deleteMessage, getTarget, upload, deleteMultipleMessage } from "../../config/networkConfig";
import cronstrue from 'cronstrue';
import moment from "moment";

export default function Notification() {

    const [newform] = Form.useForm();
    const [editform] = Form.useForm();
    const CheckboxGroup = Checkbox.Group;
    const { Option } = Select;
    const [api, contextHolder] = notification.useNotification();
    const [targets, setTargets] = useState([]);
    const [target, setTarget] = useState('Select brand');
    const [editNotif, setEditNotif] = useState('');
    const [delNotif, setDelNotif] = useState('');
    const [notif, setNotif] = useState();
    const [fNotif, setFNotification] = useState();
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [delModalOpen, setDelModalOpen] = useState(false);
    const [delSelectModalOpen, setDelSelectModalOpen] = useState(false);
    const [checkBoxVisible, setCheckBoxVisible] = useState(false);
    const [editCheckBoxVisible, setEditCheckBoxVisible] = useState(false);
    const [editDatePickerVisible, setEditDatePickerVisible] = useState(false);
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [editFileList, setEditFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const [delSelectLoading, setDelSelectLoading] = useState(false);
    const [selectedNotif, setSelectNotif] = useState([]);
    const dispatch = useDispatch();

    const showNewModal = () => {
        setNewModalOpen(true);
    };
    const showEditModal = () => {
        setEditModalOpen(true);
    };
    const showDelModal = () => {
        setDelModalOpen(true);
    };
    const showDelSelectModal = () => {
        setDelSelectModalOpen(true);
    };
    const handleNewCancel = () => {
        setNewModalOpen(false);
    };
    const handleEditCancel = () => {
        setEditModalOpen(false);
    };
    const handleDelCancel = () => {
        setDelModalOpen(false);
    };
    const handleDelSelectCancel = () => {
        setDelSelectModalOpen(false);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
      
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Text',
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: imageUrl => imageUrl?<img src={imageUrl} alt="no image" width={300} height={150} /> :<div> </div>,
        },
        {
            title: 'Schedule Time',
            dataIndex: 'cronSchedule',
            key: 'cronSchedule',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Space size = 'middle'>
                    <Button type="primary" size="small" onClick={() => getEditNotification(record)}>Edit</Button>
                    <Button type="primary" size="small" danger onClick={() => {setDelNotif(record);showDelModal()}}>Delete</Button>
                </Space>
            ),
        }
    ];

    const days = [
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 0 },
    ];

    useEffect(() => {
        getAllTarget();
        getAllMessage();
    }, [])

    useEffect(() => {
        editform.setFieldsValue(editNotif);
    }, [editNotif]);

    function getAllMessage(){
        getMessage()
        .then((response) => {
            const message = response.data.map((target, index) => {
                const scheduleTimeArr = target.cronSchedule.toString().split(' ');
                if(scheduleTimeArr[3] != '*'){
                    scheduleTimeArr[3] = (parseInt(scheduleTimeArr[3])+1).toString();
                }
                const scheduleTime = scheduleTimeArr.join(' ');
                let cronSchedule = ''
                if(scheduleTimeArr[2] !== '*' && scheduleTimeArr[3] !== '*'){
                    cronSchedule = cronstrue.toString(scheduleTime) + ' (One Time)';

                }else if(scheduleTimeArr[4] !== '*'){
                    cronSchedule = cronstrue.toString(scheduleTime) + ' (Weekly)';
                }else{
                    cronSchedule = cronstrue.toString(scheduleTime);
                }

                return{
                    key: target.notificationId,
                    scheduleId: target.scheduleId,
                    imageUrl: target.imageUrl,
                    cronSchedule: cronSchedule,
                    cronSchadule: scheduleTime,
                    sound: target.sound,
                    target: target.target,
                    text: target.text,
                    title: target.title,
                    status: target.status
                }
            })
            setNotif(message);
            setFNotification(message);
        })
        .catch((e) => {
            console.log(e);
        })
    }

    function getAllTarget(){
        getTarget()
        .then((response) => {
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            const allTarget = response.data;
            const allTopic = allTarget.map((target) => {
                return {
                    id: target.id,
                    name: target.topic,
                }
            })
            const firstTarget = allTopic[0];
            setTargets(allTopic);
            setTarget(firstTarget);
            return;
        })
        .catch((e) => {
            console.log(e);
        })
    }

    const getEditNotification = (record) => {
        const formTarget = targets.filter(obj => obj.id === record.target)
        //split cronschadule
        let schedule = '';
        let selectedDate;
        let selectedDays;
        const scheduleTimeArr = record.cronSchadule.split(' ');
        //weekly (x x * * x)
        //oneTime (x x x x *)
        //daily (x x * * *)
        if(scheduleTimeArr[2] !== '*' && scheduleTimeArr[3] !== '*'){
            schedule = 'oneTime';
            selectedDate = moment().month(parseInt(scheduleTimeArr[3])-1).date(parseInt(scheduleTimeArr[2]));
        }else if(scheduleTimeArr[4] !== '*'){
            schedule = 'weekly';
            selectedDays = scheduleTimeArr[4].split(',').map((num) => Number(num));
        }else{
            schedule = 'daily';
        }
        const time = moment().hour(parseInt(scheduleTimeArr[1])).minute(parseInt(scheduleTimeArr[0]));
        if(schedule === 'weekly'){
            setEditCheckBoxVisible(true);
            setEditDatePickerVisible(false);
        }else if(schedule === 'daily'){
            setEditCheckBoxVisible(false);
            setEditDatePickerVisible(false);
        }else if(schedule === 'oneTime'){
            setEditDatePickerVisible(true);
            setEditCheckBoxVisible(false);
        }
        const editRecord = {
            ...record,
            target: formTarget[0].name,
            date: selectedDate || '',
            schedule: schedule,
            days: selectedDays || '',
            time: time,
        }
        // console.log(editRecord);
        setEditNotif(editRecord);
        showEditModal();
    }

    const filterNotification = (value, option) => {
        setTarget(value);
        const filtered = notif.filter(item =>
            item.target == option.key
        );
        setFNotification(filtered);
    }

    const onNewFinish = (values) => {
        setLoading(true);
        const cronSchedule = processSchedule(values);
        if(values.image && values.image.fileList.length > 0){
            const formData = new FormData();
            formData.append('file', fileList[0]);
            upload(formData)
            .then((response) => {
                const formTarget = targets.filter(obj => obj.name === values.target)
                const data = {
                    title: values.title || '',
                    text: values.text,
                    imageUrl: response.path,
                    target: formTarget[0].id,
                    sound: true,
                    cronSchadule: cronSchedule,
                    status: values.status,
                }
                addNewMessage(data)
                .then((res) => {
                    if(res.statusCode === 445){
                        api['error']({
                            message: 'The maximum number of schedules has been reached',
                        });
                        setLoading(false);
                        return;
                    }
                    window.location.reload();
                })
                .catch(e => {
                    console.log(e);
                })
            })
            .catch((e) => {
                if(e.statusCode === '444'){
                    Modal.error({
                        title: 'Session expired please login again',
                        afterClose: () => {dispatch({ type: "sign_out" })}
                    })
                }
                console.log(e);
            })
        }else{
            const formTarget = targets.filter(obj => obj.name === values.target)
            const data = {
                title: values.title || '',
                text: values.text,
                imageUrl: "",
                target: formTarget[0].id,
                sound: true,
                cronSchadule: cronSchedule,
                status: values.status,
            }
            addNewMessage(data)
            .then((res) => {
                if(res.statusCode === '444'){
                    Modal.error({
                        title: 'Session expired please login again',
                        afterClose: () => {dispatch({ type: "sign_out" })}
                    })
                }
                if(res.statusCode === 445){
                    api['error']({
                        message: 'The maximum number of schedules has been reached',
                    });
                    setLoading(false);
                    return;
                }
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            })
        }
    }

    function processSchedule(values){
        if(!values.schedule){
            return '';
        }
        const selectedTime = values.time
        let cronSchedule = '';
        //form cron schedules
        //0 18 * * *
        if(values.schedule === 'daily'){
            cronSchedule = `${selectedTime.minutes()} ${selectedTime.hour()} * * *`
        }else if(values.schedule === 'oneTime'){
            const [month, day] = [values.date.month(), values.date.date()];
            cronSchedule = `${selectedTime.minutes()} ${selectedTime.hour()} ${day} ${month} *`
        }else{
            const selectedDays = (values.days).join(",");
            cronSchedule = `${selectedTime.minutes()} ${selectedTime.hour()} * * ${selectedDays}`
        }
        return cronSchedule;
    }

    const onEditFinish = (values) => {
        setEditLoading(true);
        const cronSchedule = processSchedule(values);
        if(values.image && values.image.fileList.length > 0){
            const formData = new FormData();
            formData.append('file', editFileList[0]);
            upload(formData)
            .then((response) => {
                const formTarget = targets.filter(obj => obj.name === values.target)
                const data = {
                    notificationId: editNotif.key,
                    scheduleId: editNotif.scheduleId,
                    title: values.title || '',
                    text: values.text || '',
                    imageUrl: response.path || '',
                    target: formTarget[0].id,
                    sound: true,
                    cronSchadule: cronSchedule,
                    status: values.status,
                }
                editMessage(data)
                .then((res) => {
                    // setEditLoading(false);
                    window.location.reload();
                })
                .catch(e => {
                    console.log(e);
                })
            })
            .catch((e) => {
                console.log(e);
                if(e.statusCode === '444'){
                    Modal.error({
                        title: 'Session expired please login again',
                        afterClose: () => {dispatch({ type: "sign_out" })}
                    })
                }
            })
        }else{
            const formTarget = targets.filter(obj => obj.name === values.target)
            const data = {
                notificationId: editNotif.key,
                scheduleId: editNotif.scheduleId,
                title: values.title || '',
                text: values.text || '',
                target: formTarget[0].id,
                sound: true,
                cronSchadule: cronSchedule,
                status: values.status,
            }
            editMessage(data)
            .then((res) => {
                if(res.statusCode === '444'){
                    Modal.error({
                        title: 'Session expired please login again',
                        afterClose: () => {dispatch({ type: "sign_out" })}
                    })
                }
                // setEditLoading(false);
                window.location.reload();
            })
            .catch(e => {
                console.log(e);
            })
        }
        
    }

    const deleteSelectNotif = () => {
        setDelLoading(true);
        const data = {
            notificationId: delNotif.key,
            scheduleId: delNotif.scheduleId,
        }
        deleteMessage(data)
        .then((response) => {
            if(response.statusCode === '444'){
                Modal.error({
                    title: 'Session expired please login again',
                    afterClose: () => {dispatch({ type: "sign_out" })}
                })
            }
            window.location.reload();
        })
        .catch((e) => {
            console.log(e);
            notification.open({
                message: 'Fail to delete notification'
            })
        })
    }

    const deleteSelectedNotif = () => {
        setDelSelectLoading(true);
        if(selectedNotif.length === 0 || !selectedNotif){
            api['error']({
                message: 'Please select at least 1 notification',
            });
            setDelSelectLoading(false);
        }else{
            deleteMultipleMessage(selectedNotif)
            .then((response) => {
                if(response.statusCode === '444'){
                    Modal.error({
                        title: 'Session expired please login again',
                        afterClose: () => {dispatch({ type: "sign_out" })}
                    })
                }
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
                notification.open({
                    message: 'Fail to delete notification'
                })
            })
        }
    }


    const fileProps = {
        listType: "picture", 
        fileList: fileList,
        accept: "image/*",
        maxCount: 1,
        onRemove: () => {
          setFileList([]);
        },
        beforeUpload: (file) => {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            let newFileList = [];
            newFileList.push(file);
            setFileList(newFileList);
          });
          reader.readAsDataURL(file);
          return false;
        },
        onChange: (info) => {
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            let newFileList = [...info.fileList];
            newFileList = newFileList.slice(-1);
    
            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
              if (file.response) {
                file.url = file.response.url;
              }
    
              return file;
            });
    
            setFileList(newFileList);
            return false;
        },
    };

    const editFileProps = {
        listType: "picture", 
        fileList: editFileList,
        accept: "image/*",
        maxCount: 1,
        onRemove: () => {
          setEditFileList([]);
        },
        beforeUpload: (file) => {
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            let newFileList = [];
            newFileList.push(file);
            setEditFileList(newFileList);
          });
          reader.readAsDataURL(file);
          return false;
        },
        onChange: (info) => {
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            let newFileList = [...info.fileList];
            newFileList = newFileList.slice(-1);
    
            // 2. Read from response and show file link
            newFileList = newFileList.map((file) => {
              if (file.response) {
                file.url = file.response.url;
              }
    
              return file;
            });
    
            setEditFileList(newFileList);
            return false;
        },
    }

    const checkFrequency = (values) => {
        if(values === 'weekly'){
            setCheckBoxVisible(true);
            setDatePickerVisible(false);
        }else if(values === 'daily'){
            setCheckBoxVisible(false);
            setDatePickerVisible(false);
        }else if(values === 'oneTime'){
            setDatePickerVisible(true);
            setCheckBoxVisible(false);
        }
    }

    const checkEditFrequency = (values) => {
        if(values === 'weekly'){
            setEditCheckBoxVisible(true);
            setEditDatePickerVisible(false);
        }else if(values === 'daily'){
            setEditCheckBoxVisible(false);
            setEditDatePickerVisible(false);
        }else if(values === 'oneTime'){
            setEditDatePickerVisible(true);
            setEditCheckBoxVisible(false);
        }
    }

    return (
        <div>
            {contextHolder}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', margin: `20px 20px 0px 20px`}}>
                <Select
                    popupClassName="target"
                    style={{
                        width: 120,
                    }}
                    defaultValue={target}
                    onChange={filterNotification}
                    showSearch
                >
                {targets.map((option) => (
                    <Option key={option.id} value={option.name}>
                    {option.name}
                    </Option>
                ))}
                </Select>
                <div>
                    <Button
                        type='primary'
                        onClick={showNewModal}
                    >
                        Add Notification
                    </Button>
                    <Button
                    type='primary'
                    style={{marginLeft: '30px'}}
                    icon={<DeleteFilled />}
                    danger
                    size="small"
                    onClick={showDelSelectModal}
                    >
                    </Button>
                </div>
            </div>
            <Modal title='Add notification' open={newModalOpen} footer={null}>
                <Form
                    form={newform}
                    name="addNotif"
                    autoComplete="off"
                    {...layout}
                    style={{ maxWidth: 600 }}
                    onFinish={onNewFinish}
                >
                    <Form.Item
                    label="Title"
                    name="title"
                    // rules={[{ required: true, message: 'Please input notification title' }]}
                    >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} placeholder="Optional title"/>
                    </Form.Item>

                    <Form.Item
                    label="Text"
                    name="text"
                    rules={[{ required: true, message: 'Please input notification text' }]}
                    >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                    </Form.Item>

                    <Form.Item
                    label="Image"
                    name="image"
                    // rules={[{ required: true, message: 'Please upload notification image' }]}
                    >
                        <Upload 
                        {...fileProps}
                        fileList={fileList}
                        >
                            <Button icon={<UploadOutlined />}>
                                Upload
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="target"
                        label="Target"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Select
                            placeholder="Select a target"
                        >
                            {targets.map((option) => (
                            <Option key={option.id} value={option.name}>
                            {option.name}
                            </Option>
                            ))} 
                        </Select>
                    </Form.Item>

                    <Form.Item
                    label="Schedule"
                    name="schedule"
                    rules={[{ required: true, message: 'Please choose notification frequency' }]}
                    >
                        <Select
                            placeholder="Select a frequency"
                            onChange={checkFrequency}
                        >
                            <Option value="oneTime">One Time</Option>                            
                            <Option value="daily">Daily</Option>
                            <Option value="weekly">Weekly</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="days"
                        label="Days"
                        style={{display: checkBoxVisible? 'block' : 'none'}}
                        dependencies={['weekly']}
                        rules={[
                            ({}) => ({
                              validator(_, value) {
                                if (checkBoxVisible && (!value || value.length === 0)) {
                                    return Promise.reject(new Error('Checkbox is not checked!'));
                                }
                                  return Promise.resolve();
                              },
                            }),
                          ]}
                    >
                        <CheckboxGroup options={days}/>
                    </Form.Item>

                    <Form.Item
                        name="date"
                        label="Date"
                        style={{display: datePickerVisible? 'block' : 'none'}}
                        dependencies={['oneTime']}
                        rules={[
                            ({}) => ({
                              validator(_, value) {
                                if (datePickerVisible && !value) {
                                    return Promise.reject(new Error('Schedule date is required'));
                                }
                                  return Promise.resolve();
                              },
                            }),
                          ]}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        name="time"
                        label="Time(GMT +8)"
                        rules={[{
                            required: true,
                            message: 'Time is required'
                        }]}
                    >
                        <TimePicker format="HH:mm"/>
                    </Form.Item>

                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[{
                            required: true,
                            message: 'Status is required'
                        }]}
                    >
                        <Select
                            placeholder="Select a status"
                        >
                        <Option value="RUNNING">RUNNING</Option>
                        <Option value="STOPED">STOPED</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item  {...tailLayout}>
                        <Button onClick={() => {handleNewCancel();}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ margin: '0 8px' }} loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title='Edit notification' open={editModalOpen} footer={null}>
                <Form
                    form={editform}
                    name="editNotif"
                    autoComplete="off"
                    {...layout}
                    style={{ maxWidth: 600 }}
                    onFinish={onEditFinish}
                >
                    <Form.Item
                    label="Title"
                    name="title"
                    // rules={[{ required: true, message: 'Please input notification title' }]}
                    >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                    </Form.Item>

                    <Form.Item
                    label="Text"
                    name="text"
                    // rules={[{ required: true, message: 'Please input notification text' }]}
                    >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                    </Form.Item>

                    <Form.Item
                    label="Image"
                    name="image"
                    // rules={[{ required: true, message: 'Please upload notification image' }]}
                    >
                        <Upload 
                        {...editFileProps}
                        fileList={editFileList}
                        >
                            <Button icon={<UploadOutlined />}>
                                Upload
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="target"
                        label="Target"
                        rules={[{
                            required: true,
                        }]}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                    label="Schedule"
                    name="schedule"
                    rules={[
                        ({}) => ({
                          validator(_, value) {
                            if ((editform.getFieldValue('time') && !value)) {
                                return Promise.reject(new Error('Schedule is required'));
                            }
                              return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                        <Select
                            placeholder="Select a frequency"
                            onChange={checkEditFrequency}
                        >
                            <Option value="oneTime">One Time</Option>                            
                            <Option value="daily">Daily</Option>
                            <Option value="weekly">Weekly</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="days"
                        label="Days"
                        style={{display: editCheckBoxVisible? 'block' : 'none'}}
                        dependencies={['weekly']}
                        rules={[
                            ({}) => ({
                              validator(_, value) {
                                if (editCheckBoxVisible && (!value || value.length === 0)) {
                                    return Promise.reject(new Error('Checkbox is not checked!'));
                                }
                                  return Promise.resolve();
                              },
                            }),
                          ]}
                    >
                        <CheckboxGroup options={days}/>
                    </Form.Item>

                    <Form.Item
                        name="date"
                        label="Date"
                        style={{display: editDatePickerVisible? 'block' : 'none'}}
                        dependencies={['oneTime']}
                        rules={[
                            ({}) => ({
                              validator(_, value) {
                                if (editDatePickerVisible && !value) {
                                    return Promise.reject(new Error('Schedule date is required'));
                                }
                                  return Promise.resolve();
                              },
                            }),
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        name="time"
                        label="Time(GMT +8)"
                        // dependencies={['schedule']}
                        rules={[
                            ({}) => ({
                              validator(_, value) {
                                if ((editform.getFieldValue('schedule') && !value)) {
                                    return Promise.reject(new Error('Time is required'));
                                }
                                  return Promise.resolve();
                              },
                            }),
                          ]}
                    >
                        <TimePicker format="HH:mm"/>
                    </Form.Item>

                    <Form.Item
                        name="status"
                        label="Status"
                        // rules={[{ required: true }]}
                    >
                        <Select
                            placeholder="Select a status"
                        >
                        <Option value="RUNNING">RUNNING</Option>
                        <Option value="STOPED">STOPED</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item  {...tailLayout}>
                        <Button onClick={() => {handleEditCancel();}}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ margin: '0 8px' }} loading={editLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Delete Notification"
                open={delModalOpen}
                onCancel={handleDelCancel}
                onOk={deleteSelectNotif}
                style={{ textAlign: 'center'}}
                confirmLoading={delLoading}
            >
                <p>Delete notification with title "{delNotif.title}"?</p>
            </Modal>

            <Modal
                title="Delete Selected Notification"
                open={delSelectModalOpen}
                onCancel={handleDelSelectCancel}
                onOk={deleteSelectedNotif}
                style={{ textAlign: 'center'}}
                confirmLoading={delSelectLoading}
            >
                <p>Delete selected notification?</p>
            </Modal>

            <Table
                style={{ margin: 20 }}
                columns={columns}
                dataSource={fNotif}
                pagination={false}
                rowSelection={{
                    onChange: (selectedRowKeys, selectedRows) => {
                      const selectedArr = selectedRows.map((row) => {
                        return {
                            notificationId: row.key,
                            scheduleId: row.scheduleId,
                        }
                      })
                      setSelectNotif(selectedArr);
                    },
                }}
            >
            </Table>
        </div>
    );
}