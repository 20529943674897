import axios from "axios";

export {
  login,
  getUser,
  addNewUser,
  editUser,
  deleteUser,
  getTarget,
  addNewTarget,
  editTarget,
  deleteTarget,
  getNotification,
  addNewNotification,
  editNotification,
  deleteNotification,
  getSchedule,
  addNewSchedule,
  editSchedule,
  deleteSchedule,
  upload,
  getMessage,
  addNewMessage,
  editMessage,
  deleteMessage,
  deleteMultipleMessage,
};

const instance = axios.create({
  baseURL: "https://h5msgapi.xyz/"
});

instance.interceptors.request.use((config) => {
  const expirationTime = localStorage.getItem('expirationTime');
  if(expirationTime){
    if(expirationTime < Date.now()){
      const errorData = {
        response: {
          data: {
            statusCode:  '444',
            message: 'Token Expired'
          }
        }
      };
      return Promise.reject(errorData);
    }
  }
  return Promise.resolve(config);
})

async function callApi(
    {url, method, data}
) {
  try {
    instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    const response = await instance({
      url: url,
      method: method,
      data: data
    });
    console.log(response);
    return response.data;
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
}

async function login(data) {
  try {
    const response = await axios.post('https://h5msgapi.xyz/auth/login', data);
    console.log(response);
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('expirationTime', Date.now() + 3600 * 1000);
    return response.data;
  } catch (error) {
    return error;
  }
}

//User
async function getUser(){
  return callApi({
    url: "/users",
    method: "get",
    data: ""
  }).then(res => {
    return res;
  })
}

async function addNewUser(data){
  return callApi({
    url: "/users",
    method: "post",
    data: data
  }).then(res => {
    return res;
  })
}

async function editUser(data){
  return callApi({
    url: `/users/${data[1]}`,
    method: "patch",
    data: data[0]
  }).then(res => {
    return res;
  })
}

async function deleteUser(data){
  return callApi({
    url: `/users/${data}`,
    method: 'delete',
  }).then(res => {
    return res;
  })
}

//Target
async function getTarget(){
  return callApi({
    url: "/target",
    method: "get",
    data: ""
  }).then(res => {
    return res;
  })
}

async function addNewTarget(data){
  return callApi({
    url: "/target",
    method: "post",
    data: data
  }).then(res => {
    return res;
  })
}

async function editTarget(data){
  return callApi({
    url: `/target/${data[1]}`,
    method: "patch",
    data: data[0]
  }).then(res => {
    return res;
  })
}

async function deleteTarget(data){
  return callApi({
    url: `/target/${data}`,
    method: 'delete',
  }).then(res => {
    return res;
  })
}

//Notification
async function getNotification(){
  return callApi({
    url: "/notification",
    method: "get",
    data: ""
  }).then(res => {
    return res;
  })
}

async function addNewNotification(data){
  return callApi({
    url: "/notification",
    method: "post",
    data: data
  }).then(res => {
    return res;
  })
}

async function editNotification(data){
  return callApi({
    url: `/notification/${data[1]}`,
    method: "patch",
    data: data[0]
  }).then(res => {
    return res;
  })
}

async function deleteNotification(data){
  return callApi({
    url: `/notification/${data}`,
    method: 'delete',
  }).then(res => {
    return res;
  })
}

//Schedule
async function getSchedule(){
  return callApi({
    url: "/schedule",
    method: "get",
    data: ""
  }).then(res => {
    return res;
  })
}

async function addNewSchedule(data){
  return callApi({
    url: "/schedule",
    method: "post",
    data: data
  }).then(res => {
    return res;
  })
}

async function editSchedule(data){
  return callApi({
    url: `/schedule/${data[1]}`,
    method: "patch",
    data: data[0]
  }).then(res => {
    return res;
  })
}

async function deleteSchedule(data){
  return callApi({
    url: `/schedule/${data}`,
    method: 'delete',
  }).then(res => {
    return res;
  })
}

async function upload(data) {
  const expirationTime = localStorage.getItem('expirationTime');
  if(expirationTime){
    if(expirationTime < Date.now()){
      const errorData = {
        response: {
          data: {
            statusCode:  '444',
            message: 'Token Expired'
          }
        }
      };
      return Promise.reject(errorData);
    }
  }
  const response = await axios.post('https://h5msgapi.xyz/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }});
  console.log(response);
  return response.data;
}

async function getMessage() {
  return callApi({
    url: "/message",
    method: "get",
    data: ""
  }).then(res => {
    return res;
  })
}

async function addNewMessage(data){
  return callApi({
    url: "/message",
    method: "post",
    data: data
  }).then(res => {
    return res;
  })
}

async function editMessage(data){
  return callApi({
    url: "/message",
    method: "patch",
    data: data
  }).then(res => {
    return res;
  })
}

async function deleteMessage(data){
  return callApi({
    url: "/message",
    method: "delete",
    data: data
  }).then(res => {
    return res;
  })
}

async function deleteMultipleMessage(data){
  return callApi({
    url: "/message/multiple",
    method: "delete",
    data: data
  }).then(res => {
    return res;
  })
}

