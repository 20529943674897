import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "sign_out" });
  });

  return <Redirect to="/" />;
}
